<template>
    <div class="header" id="headerForFooter">
<!--      <img v-show="showMenuServices"  src="@/assets/img/background_header_menu.png" alt="logo" class="header_background">-->
        <div class="container__header">
            <router-link to="/" class="logo">
<!--
                <img src="@/assets/img/LogoMobileSvg.svg" alt="logo" class="logo__img">
-->
              <img src="https://dbwImages.b-cdn.net/LogoMobileSvg.svg" alt="logo" class="logo__img">

            </router-link>

            <nav v-if="this.menuForServices" class="nav__container">
                <router-link :class="{'nav__container__active': $route.name === 'reel'}" :to="{name: 'reel'}">
                    {{ this.menuForServices[2].name }}
                </router-link>
                <router-link :class="{'nav__container__active': $route.name === 'about'}" :to="{name: 'about'}">
                    {{ this.menuForServices[3].name }}
                </router-link>
                <router-link :class="{'nav__container__active': $route.name === 'portfolio'}" :to="{name: 'portfolio'}">
                    {{ this.menuForServices[0].name }}
                </router-link>
                <router-link :class="{'nav__container__active': $route.name === 'blog'}" :to="{name: 'blog'}">
                    {{ this.menuForServices[1].name }}
                </router-link>
                <template v-if="this.menuForServices">
                    <div @mouseover="openServicesMenu()" class="drop__services"><a
                            :class="{'nav__container__active': $route.name === 'services'}">{{
                        this.menuForServices[4].name
                        }}</a>
                        <img :class="[this.showMenuServices ? 'services__drop__active' : 'services__drop']"
                             src="../assets/img/drop_down_services.png" alt="drop__services">
                    </div>

                  <transition name="fade1">
                    <open-services-menu
                        :is-footer="true"
                        :footer-position="true"
                        @closeServiceMenu="closeServices"
                        v-show="showMenuServices"
                        :is-open-services-menu="showMenuServices"
                    />
                  </transition>
                </template>


            </nav>

<!--            <a href="#" v-scroll-to="{ el: '#header', duration: 1000 }" class="border_btn_redisign">

                <img src="@/assets/img/paylog_redisigne.png" alt="payload" class="btn__img">
                <p class="btn__text">CONTACT</p>

            </a>-->

          <a @mouseover="contactMouseOver = true" @mouseleave="contactMouseOver = false"
             href="#" v-scroll-to="{ el: '#contact', duration: 1000 }" class="border_btn_redisign">

            <img v-if="!contactMouseOver" src="@/assets/img/paylog_redisigne.png" alt="payload" class="btn__img">
            <img v-if="contactMouseOver" src="@/assets/img/Polygon5.svg" alt="payload" class="btn__img">

            <p class="btn__text" :class="[contactMouseOver ? 'btn__text_red' : 'btn__text']">CONTACT</p>

          </a>



          <div class="btn__burger" @click="openMenu">
                <div class="button__burger" :class="{ open: showMenu }">
                    <span></span>
                    <span></span>
                </div>
            </div>

        </div>
<!--        <transition name="fade1">
            <open-services-menu
                    :footer-position="true"
                    @closeServiceMenu="closeServices"
                    v-show="showMenuServices"
                    :is-open-services-menu="showMenuServices"
            />
        </transition>-->

        <transition name="fade">
            <open-menu-footer
                    v-show="showMenu"
                    :is-open="showMenu"
                    @close="openMenu"
            />
        </transition>

    </div>
</template>

<script>
import {bus} from '../main'
import {mapGetters} from 'vuex'
/*import OpenMenuFooter from "./OpenMenuFooter";
import OpenServicesMenu from "./OpenServicesMenu.vue";*/
const OpenMenuFooter = () => import('./OpenMenuFooter');
const OpenServicesMenu = () => import('./OpenServicesMenu.vue');


export default {
    name: "HeaderForFooter",
    components: {OpenServicesMenu, OpenMenuFooter},
    data() {
        return {
            showMenu: false,
/*
            menu: [],
*/
/*
            menuServices: [],
*/
            showMenuServices: false,
            contactMouseOver: false,
        }
    },
    computed: {
        ...mapGetters([
            'menuLinkText',
            'menuForServices'
        ])
    },
    created() {
       /* this.$store.dispatch('getMenuServices').then(() => {
            this.menuServices = this.menuForServices;
        });*/
    },

    mounted() {
        /*const header = this.$scrollmagic.scene({
            triggerElement: '#body',
            triggerHook: 0,
            offset: 120,
            // duration: document.height
        })
            .setClassToggle('#header', 'is-hidden')
        this.$scrollmagic.addScene(header)*/

      /*  this.$store.dispatch('getMenuLinkText').then(() => {
            document.body.style.overflow = !this.showMenu ? '' : 'hidden';

            this.menu = this.menuLinkText;
        });*/
    },
    methods: {
        closeServices() {
            this.showMenuServices = false
            document.body.style.overflow = 'auto'
        },
        openServicesMenu() {
            this.showMenuServices = true;
            document.body.style.overflow = !this.showMenuServices ? '' : 'hidden';
        },
        showReel: function (el) {
            bus.$emit('stateReel', el);
        },
        openMenu() {
            console.log(document.body.style.overflow);
            this.showMenu = !this.showMenu;
            document.body.style.overflow = !this.showMenu ? '' : 'hidden';
        },
    },
    beforeDestroy() {
        if (this.showMenu) {
            this.openMenu();
        }
    },

}
</script>

<style scoped lang="scss">
.header_background{
  display: block;
  position: absolute;
  top: 0;
  right: 4vw;
  z-index: 555;
  width: 29vw;
  rotate: 180deg;
}

.drop__services {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 100;
  cursor: pointer;
}

.drop__services a {
  user-select: none;
}

.services__drop__active {
  transform: rotate(360deg);
  margin-left: 0.417vw;

}

.services__drop {
  margin-left: 0.417vw;
  transform: rotate(180deg);
}

.header {
  width: 100%;
  opacity: 1;
  overflow: hidden;


  .container__header {
    display: flex;
    justify-content: space-between;
  }

  &.is-hidden {
    opacity: 0;
    pointer-events: none;
    z-index: -1;
  }


  nav {
    font-size: 0.938vw;
    color: #FFFFFF;
    font-family: 'Benzin-Regular';

    a {
      font-size: 0.938vw;
      color: #FFFFFF;
      font-family: 'Benzin-Regular';
      text-transform: uppercase;
      transition: 0.5s;

      &:hover {
        color: #DB1023;
      }

    }
  }
}

.logo {
  margin-left: 8.3%;
  margin-top: 0.677vw;
  width: 4.740vw;
  height: 6.042vw;
}

.logo__img {
  width: 100%;
  height: 100%;
}

.border_btn_redisign {
  position: relative;
  top: -0.260vw;
  /*
  right: -7.60vw;
  */
}

.border_btn_redisign img {
  width: 6.354vw;
  height: 4.740vw;
}

.btn__text {
  font-family: 'Benzin-Regular';
  font-size: 0.938vw;
  position: absolute;
  top: 2.083vw;
  color: #FFFFFF;
  left: 50%;
  transform: translate(-50%);
}
.btn__text_red{
  font-family: 'Benzin-Regular';
  font-size: 0.938vw;
  position: absolute;
  top: 2.083vw;
  color: red;
  left: 50%;
  transform: translate(-50%);
}

.nav__container {
  display: flex;
  justify-content: space-between;
  max-width: 46.03vw;
  width: 100%;
  margin-top: 1.927vw;

  margin-left: 15.94vw;
  margin-right: 13.44vw;

  height: 20%;
}

.nav__container__active {
  font-size: 0.938vw !important;;
  text-transform: uppercase !important;;
  transition: 0.5s !important;;
  color: red !important;
}

@media screen and(max-width: 991px) {
  .header_background{
    display: none;
  }
  .logo {
    margin-left: 6.4%;
    width: 11.133vw;
    height: 13.867vw;
    margin-top: 8.333vw;
  }
  .border_btn_redisign {
    display: none;
  }
  .nav__container {
    display: none;
  }
  .btn__burger {
    width: 8.533vw;
    height: 3.200vw;
    margin-right: 10.133vw;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
  }

  .button__burger {
    margin-top: 10.133vw;
    z-index: 999999;
    top: 10px;
    width: 32px;
    height: 12px;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    transition: 0.5s;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
  }

  .button__burger span {
    display: block;
    position: absolute;
    height: 0.800vw;
    width: 8.533vw;
    background: rgba(229, 220, 220, 1);
    border-radius: 0;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .35s ease-in-out;
  }

  .button__burger span:nth-child(1) {
    top: 0.800vw;
    transform-origin: left center;
  }

  .button__burger span:nth-child(2) {
    margin-left: 1.867vw;
    width: 6.667vw;
    top: 3.733vw;
    transform-origin: left center;
  }

  .button__burger.open span:nth-child(2) {
    width: 8.533vw;
    transform: rotate(-45deg);
    top: 6.400vw;
    left: -2.133vw;
  }

  .button__burger.open span:nth-child(1) {
    transform: rotate(45deg);
    top: 0.533vw;
    left: -0.267vw;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
  {
    opacity: 0;
  }

  .fade-leave-active {
    transition-delay: .6s;
  }

  .fadeButton-enter-active, .fadeButton-leave-active {
    transition: opacity .3s;
  }
  .fadeButton-enter, .fadeButton-leave-to /* .fade-leave-active до версии 2.1.8 */
  {
    opacity: 0;
  }

}

</style>